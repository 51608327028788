.App {
  text-align: center;
  position: relative;
}
.background-svg {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 1 !important; */
  pointer-events: none;
}

section {
  position: relative;
  z-index: 2;
}
.accessibility-btn {
  z-index: 100;
}
body.readable,
body.readable .container-main h1 {
  font-family: Helvetica !important;
}

body.readable .container-main h1 {
  line-height: initial !important;
}
html.invert {
  filter: invert(1);
}
html.bright-1 {
  filter: brightness(50%);
}
html.bright-2 {
  filter: brightness(150%);
}
html.grayscale {
  filter: grayscale(1);
}
html.contrast-1 {
  filter: contrast(50%);
}
html.contrast-2 {
  filter: contrast(150%);
}

html.hilight-links a {
  text-decoration: underline;
}
.btn:focus-visible,
.btn:active:focus-visible,
.toggle-checkbox input:focus-visible + label,
*:focus-visible {
  outline: solid #0a7cff 3px !important;
  border-radius: 5px;
}
.accessibly-app-reading-mask {
  display: block !important;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000000000000020000;
}

.accessibly-app-reading-mask-top {
  top: 0;
}

.accessibly-app-reading-mask-bottom {
  bottom: 0;
}

.content-space {
  position: absolute;
  height: 200px;
  width: 100%;
  background-color: transparent;
  pointer-events: none;
}
